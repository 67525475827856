import { Address } from "interfaces";
const dayjs = require("dayjs");

export const changeAddressFieldsName = (data: Address) => {
  return {
    "Created Date": dayjs(data.createdAt).format("MMM DD, YYYY"),
    "First Name": data.firstName,
    "Last Name": data.lastName,
    Email: data.email,
    "Address Line 1": data.addressLine1,
    "Address Line": data.addressLine2,
    City: data.city,
    State: data.state,
    ZIP: data.zipcode,
    "Tracking Number": data.trackingNumber,
    "Store Number": data.storeNumber,
    "Kit Shipping Date": data.shippingDate
      ? dayjs(data.shippingDate).format("MMM DD, YYYY")
      : "",
    "Kit outbound tracking": data.outbound,
    "Bin inbound tracking": data.inbound,
    "#4 LDPE": data.ldpe,
    Glass: data.glass,
    Silicone: data.silicone,
    Cardboard: data.cardboard,
    "#5 PP": data.pp,
    Metal: data.metal,
    "#7 Other": data.other,
    Contaminated: data.contaminated,
    "#2 HDPE": data.hdpe,
    "Mixed Paper": data.mixedPaper,
    "#3 PVC": data.pvc,
    "#1 PET": data.pet,
    "#6 PS": data.ps,
    Ceramic: data.ceramic,
    "Misc. Recyclable Materials": data.miscRecyclableMaterials,
    "Hot Tool - Donation": data.hotToolPieces,
    "Hot Tool Donation Weight": data.weight,
    "Hot Tool - Recycle": data.recycleWeight,
    "Hot Tool Recycle Weight": data.recyclePieces,
  };
};
