import { Box } from '@mui/material';
import { DashboardIframe } from 'helpers';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useAxios } from 'hooks';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { colors, fontsWeight } from 'utils';

export const Dashboard = () => {

  const initialValue = {
  role: '',
  program: '',
  iframeUrl: ``,
  programUrl: ``
}
  const [loading, setLoading] = useState(true);
  const [hasIframe, setHasIframe] = useState(initialValue);
  const [iframeUrl, setIframeUrl] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const AxiosClient = useAxios();
  const navigate = useNavigate();


  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  const fetchDashboardUrl =useCallback(
    async (url:string) => {
      try {
        const data = url.split("/");
  
        const sourceId = data[data.length - 1];
        const sourceType = data[data.length - 2];
  
        const { data:response } = await AxiosClient.get(`addresses/get-dashboard?resource_type=${sourceType}&resource_id=${sourceId}`);
        
        setIframeUrl(response?.dashboardUrl);
      } catch (error) {
        console.error("Error dashboard public URL:", error);
      }
    },[AxiosClient])
  

  useEffect(() => {
    setLoading(true)
    const dashboardType = searchParams.get("dashboardType")

    const hasRoleIframe : any = DashboardIframe.find(
      (item:any) =>
        item.role === user.role &&
        user?.programs
          ?.map((program:string) => program.toLowerCase())
          .includes(item.program.toLowerCase())
    ) ?? {}

    if (hasRoleIframe) {
      setHasIframe((prev) => ({ ...prev, ...hasRoleIframe }));
      hasRoleIframe?.iframeUrl !== '' &&
     dashboardType==='program'? fetchDashboardUrl(hasRoleIframe.programUrl):fetchDashboardUrl(hasRoleIframe.iframeUrl);
    } 
    setLoading(false);
    return;
  }, [DashboardIframe, user.role,searchParams]);

  const handleBack = () => {
    navigate('/addresses');
 };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '0 16px',
        }}
      >
        
       <Button
  onClick={handleBack}
  sx={{
    ...ButtonStyles,
    display: 'flex',
    alignItems: 'center',
  }}
  variant="outlined"
  startIcon={<ArrowBackIcon />} 
>
  Back
</Button>
      </Box>
      {hasIframe && hasIframe?.iframeUrl!== '' ? (
        <div>
          <iframe
            title="dashboard"
            style={{
              width: '100%',
              height: '900px',
              border: '1px solid #ddd',
              borderRadius: '4px',
            }}
            src={iframeUrl}
          ></iframe>
        </div>
      ) :
      (
        <Navigate to="/addresses" />
      )}
    </Box>
  );
};

const ButtonStyles = {
  width: '100px',
  height: '40px',
  background: colors.lightRed,
  color: colors.colorWhite,
  borderRadius: 5,
  margin: '5px',
  fontWeight: fontsWeight.fontMedium,
  fontSize: '16px',
  lineHeight: '24px',
  outline: 'none',
  border: 'none',
  textTransform: 'capitalize',
  '&:hover': {
    background: colors.lightRed,
    border: 'none',
  },
};
