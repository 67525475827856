import { Box, CircularProgress } from "@mui/material";
import { useAuthContext } from "context";
import { Navigate, Outlet } from "react-router-dom";

export const RequireAuth = () => {
  const { isAuthenticated,initializing, getUser } = useAuthContext();
  
if (initializing) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress />
    </Box>
  );
}

  return isAuthenticated && getUser() ? <Outlet /> : <Navigate to="/" />;
};
